import './bootstrap';
import '../css/app.css';

import './cookie';
// import './firebase';

import.meta.glob([
  '../images/**',
  // '../js/Images/**/*.vue',
  // '../fonts/**',
]);

// const imgs = import.meta.glob('../../Images/');

import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { VueFire, VueFireAuth } from 'vuefire';
import { firebaseApp } from './firebaseApp'
import { i18nVue } from 'laravel-vue-i18n'

// import { moment } from 'vue-moment';
// import { vueMoment } from 'vue-moment';

const appName = import.meta.env.VITE_APP_NAME || 'MeditBe';

let lang = 'fr';

let tmp = window.location.href.match(/\/([a-z]{2})\//)
if (tmp && tmp.length) {
  lang = tmp[1]
}

window.app = createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    var app = createSSRApp({ render: () => h(App, props) })

    app.use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(VueFire, {
        firebaseApp,
        modules: [
          // ... other modules
          VueFireAuth(),
        ],
      })
      // .use(moment)
      // .use(vueMoment)
      .use(i18nVue, {
        lang: lang, // localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fr',
        fallbackLocale: 'en',
        resolve: async lang => {
          const langs = import.meta.glob('../../lang/*.json');
          return await langs[`../../lang/${lang}.json`]();
        }
      })
      .mount(el);

    return app;
  },
  progress: {
    delay: 2000, // The delay after which the progress bar will appear, in milliseconds...
    color: '#4B5563', // The color of the progress bar...
  },
});

// Refresh CSRF Token
// refresh_csrf() first call in app.blade.php
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
window.refreshToken = () => {
  // console.debug('window.refreshToken()');
  axios.get('/refresh-csrf').then((response) => {
    var newCsrfToken = response.data; // the new token
    // console.error('refresh-csrf:', csrfToken, newCsrfToken);
  });
}
setInterval(refreshToken, 3600 * 1000); // 1 hour 

// Format number (price, ...)
window.number_format = (
  num,
  decimals = 0,
  decimal_separator = ".",
  thousands_separator = ",") => {

  // Strip all characters but numerical ones.
  num = (num + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+num) ? 0 : +num,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_separator === 'undefined') ? ',' : thousands_separator,
    dec = (typeof decimal_separator === 'undefined') ? '.' : decimal_separator,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  num = s.join(dec);
  num = num.replace(',00', '');

  return num;
};

window.durations = {
  '1d': '1 jour', // => '24 hour access',
  '3d': '3 jours', // => '72 hour access',
  '7d': '7 jours', // => '7 day access',
  '1w': '1 semaine', // => '7 day access', 
  '1m': '1 mois', // => '1 month (31-day) access',
  '2m': '2 mois', // => '2 month (61-day) access',
  '3m': '3 mois', // => '3 month (92-day) access',
  '6m': '6 mois', // => '6 month (183-day) access',
  '$rc_six_month': '6 mois', // => '6 month (183-day) access',
  '1y': '1 an', // => '1 year (365-day) access',
  '*': '200 ans', // => '200 year access',
};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return (this.charAt(0).toUpperCase() + this.slice(1)) || ""
  },
  enumerable: false
});